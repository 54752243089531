
(function () {
    whyChooseCasha();
    whyPage();
    jQuery(".youtube-video").YTPlayer();
}());
function whyPage() {
    $('.casha-custom-tab').on('click', function(e) {
        $('.casha-container').removeClass('active');
        $('#' + $(this).data('id')).addClass('active');
    });
}
function whyChooseCasha() {
    /*--water_wheel_carousel--*/
    if ($('#carousel').length <= 0) {
        return;
    }
    if (window.matchMedia("(max-width: 767px)").matches) {
        var carousel = $("#carousel").waterwheelCarousel({
            flankingItems: 2,
            separation: 300,
            speed:250,
        });
    } else if (window.matchMedia("(max-width: 500px)").matches) {
        var carousel = $("#carousel").waterwheelCarousel({
            flankingItems: 2,
            separation: 200,
            speed: 250,
        });
    } else {
        var carousel = $("#carousel").waterwheelCarousel({
            flankingItems: 2,
            separation: 360,
            speed:250,
        });
    }


    $("#carousel").swipe( {
    swipeStatus:function(event, phase, direction, distance)
    {
        if (phase=="end"){
            if(direction == "right") { 
                carousel.prev();
            }
            else if(direction =="left") {
                carousel.next();
            }else { return false;}
        }
    },
    triggerOnTouchEnd:false,
    threshold:100
    });
    $('#prev').on('click', function (){
    carousel.prev();
    return false;
    });
    $('#next').on('click', function (){
    carousel.next();
    return false;
    });
    /*--water_wheel carousel_script_end--*/
    
}

$('#close-video').click(function(){
    $('video')[0].load()
})
$(document).ready(function(){

    var terms = window.location.href.substr(27)
    if(terms == '/privacy-policy/#terms-of-use'){
        $('html, body').animate({
            scrollTop: $("p:contains('Breaches of these Terms and Conditions')").offset().top - 155
        }, 2000);
    }

   $('video').bind('contextmenu',function() { return false; });
    var myState = {
        pdf: null,
        currentPage: 1,
        zoom: 1
    }
    $('#abc').on('click', function() {
        var more = $(this).data('url-next');
        page = more.split('=');
        $.ajax({
            type : "POST",
            url: "/article/news/load",
            data :{page:page[1]},
            dataType : 'json',
            processData: true,
            success : function(data) {
                $('#news_con').append(data.view);
                $('#abc').data('url-next',data.next)

                if(data.next == null) {
                    $('.load-more').css('display', 'none');
                } else {
                    $('.load-more').css('display', 'flex');
                }

            }
        })

    })

    // initPDFViewer($('#pdf-url').val())
    // let currentPageIndex = 0;
    // let pdfInstance = null;
    // let totalPagesCount = 0;
    
    // window.initPDFViewer = function(pdfURL) {
    //   pdfjsLib.getDocument(pdfURL).then(pdf => {
    //     pdfInstance = pdf;
    //     totalPagesCount = pdf.numPages;
    //     initPager();
    //     render();
    //   });
    // };
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
    }

    function checkCookie() {
        var accepted = getCookie("accepted-terms");
        if (accepted) {
            setCookie('accepted-terms', true, 1);
            $('.cookie-div').css('display','none');
        } else {
            $('.cookie-div').css('display','block');
        }
    }

    checkCookie();
    $('#accept').on('click', function () {
        setCookie('accepted-terms', true, 1);
        $('.cookie-div').css('display','none');
    });


   
})